<template>
  <div>
    <div
      class="o-mobileMenu o-flex -column -spaceBetween t-body2"
      data-cy="MobileMenu"
      :class="{ '-open': isMenuOpen }">
      <div class="u-spacer" />
      <div
        data-cy="MobileMenu__content">
        <div
          v-if="communityNavEntries.length"
          class="t-h3 t-fontWeightSemibold u-marginLeft5 u-marginBottom4">
          {{ $t('nav.communities') }}
        </div>
        <template v-for="entry in communityNavEntries">
          <router-link
            :key="`community-${entry.route.params.id}`"
            v-slot="{ href, navigate }"
            :to="entry.route">
            <a
              class="o-mobileMenu__link"
              :href="href"
              @click="goAndClose($event, navigate)">
              <SvgIcon
                :type="`menu_outline_${entry.icon}`"
                data-cy="MobileMenu__entryIcon" />
              {{ entry.label }}
            </a>
          </router-link>
        </template>
        <hr
          v-if="communityNavEntries.length"
          class="o-mobileMenu__divider" />
        <div
          class="t-h3 t-fontWeightSemibold u-marginLeft5 u-marginBottom4"
          data-cy="MobileMenu__title">
          {{ $t('nav.more') }}
        </div>
        <template v-for="(entry, index) in menuEntries">
          <hr
            v-if="entry.divider"
            :key="`divider-${index}`"
            class="o-mobileMenu__divider"
            data-cy="MobileMenu__divider" />
          <router-link
            v-else
            :key="`${entry.label}-route`"
            v-slot="{ href, navigate }"
            :to="entry.route"
            data-cy="MobileMenu__entry">
            <a
              class="o-mobileMenu__link"
              :href="href"
              @click="goAndClose($event, navigate)">
              <SvgIcon
                :type="`menu_outline_${entry.icon}`"
                data-cy="MobileMenu__entryIcon" />
              {{ entry.label }}
            </a>
          </router-link>
        </template>
        <hr
          class="o-mobileMenu__divider"
          data-cy="MobileMenu__divider" />
        <LanguageSelector
          upwards
          data-cy="MobileMenu__languageSelector">
          <template #default="language">
            <div class="o-mobileMenu__link t-caption1 t-fontWeightMedium u-marginBottom0 u-marginTop0">
              <SvgIcon type="language" />
              {{ language.label }}
              <SvgIcon
                type="arrow_dropdown"
                class="s-languageDropdownIcon u-marginRight0 t-caption1" />
            </div>
          </template>
        </LanguageSelector>
        <router-link
          v-slot="{ href, navigate }"
          :to="{
            name: 'profile.profile',
          }">
          <a
            class="o-mobileMenu__link"
            data-cy="MobileMenu__openProfile"
            :href="href"
            @click="goAndClose($event, navigate)">
            <SvgIcon
              type="menu_outline_user"
              data-cy="MobileMenu__profileIcon" />
            {{ $t('pages.my_profile') }}
          </a>
        </router-link>
      </div>
    </div>
    <div class="o-tabBar">
      <router-link
        v-for="(tabbarEntry, index) in tabbarEntries"
        :key="`tabbar-${index}`"
        v-slot="{ href, navigate, isExactActive }"
        :to="tabbarEntry.route">
        <a
          class="o-tabBar__link"
          :class="{ '-active': isExactActive && !isMenuOpen }"
          data-cy="MobileMenu__tabBarEntry"
          :href="href"
          @click="goAndClose($event, navigate)">
          <div>
            <SvgIcon
              v-if="isExactActive && !isMenuOpen"
              :type="`menu_filled_${tabbarEntry.icon}`"
              data-cy="MobileMenu__tabBarIconActive" />
            <SvgIcon
              v-else
              :type="`menu_outline_${tabbarEntry.icon}`"
              data-cy="MobileMenu__tabBarIcon" />
          </div>
          <span>{{ tabbarEntry.label }}</span>
        </a>
      </router-link>
      <a
        v-if="navLinks.length > 5"
        class="o-tabBar__link"
        :class="{ '-active': isMenuOpen }"
        data-cy="MobileMenu__tabBarMenu"
        href="#"
        @click.prevent="isMenuOpen = !isMenuOpen">
        <div>
          <SvgIcon
            v-if="isMenuOpen"
            :type="`menu_filled_dots`"
            data-cy="MobileMenu__tabBarMenuOpen" />
          <SvgIcon
            v-else
            :type="`menu_outline_dots`"
            data-cy="MobileMenu__tabBarMenuClosed" />
        </div>
        <span>{{ $t('nav.more') }}</span>
      </a>
    </div>
  </div>
</template>

<script>
import navigationMixin from './navigationMixin'

export default {
  mixins: [navigationMixin],
  data() {
    return {
      isMenuOpen: false,
    }
  },
  computed: {
    menuEntries() {
      // remove menu entries shown in tabbar
      const menuEntries = this.navigation.filter((entry) => !this.tabbarEntries.some((tabbarEntry) => tabbarEntry.route.name === entry.route?.name))
      // return superfluous dividers
      return menuEntries.filter((entry, index) => {
        if (!entry.divider) {
          return true
        }
        // starting divider
        if (index === 0) {
          return false
        }
        // ending divider
        if (index === menuEntries.length - 1) {
          return false
        }
        // divider following divider
        if (menuEntries[index - 1]?.divider) {
          return false
        }

        return true
      })
    },
    navLinks() {
      // remove divider entries
      return this.navigation.filter((entry) => !entry.divider)
    },
    tabbarEntries() {
      if (this.navLinks.length <= 5) {
        return this.navLinks
      }
      return this.navLinks.slice(0, 4)
    },
  },
  watch: {
    $route() {
      this.isMenuOpen = false
    },
  },
  methods: {
    goAndClose(event, navigate) {
      this.isMenuOpen = false
      navigate(event)
    },
  },
}
</script>
