import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'

import communitiesApi from '../api/communities'

export const useCommunitiesStore = defineStore('communities', {
  state: () => ({
    communities: useLocalStorage('communities', []),
  }),

  actions: {
    async updateCommunities() {
      const response = await communitiesApi.getCommunities()
      this.communities = response.data
    },
  },
})
