var render = function render(){var _vm=this,_c=_vm._self._c;return _c('portal',{attrs:{"to":"modal"}},[_c('transition',{attrs:{"name":"modal"}},[(_vm.isOpen)?_c('div',{staticClass:"c-modal__backdrop",on:{"click":function($event){_vm.isOpen = false},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;_vm.isOpen = false}}},[(_vm.image || _vm.fullscreen)?_c('button',{staticClass:"o-unstyled c-modal__iconClose o-flex -alignCenter -center",class:{
          '-fullscreen': _vm.fullscreen,
        },on:{"click":function($event){_vm.isOpen = false}}},[(_vm.fullscreen)?[_vm._v(" "+_vm._s(_vm.$t('generic.close'))+" ")]:_vm._e(),_c('SvgIcon',{attrs:{"type":"close","medium":""}})],2):_vm._e(),_c('div',{staticClass:"c-modal__wrapper",class:{
          '-image': _vm.image,
          '-fullscreen': _vm.fullscreen,
        },attrs:{"data-cy":"GenericModal__window"}},[_c('div',{staticClass:"c-modal",class:{
            '-large': _vm.large,
            '-wide': _vm.wide,
            '-mediumWide': _vm.mediumWide,
            '-scroll': _vm.scroll,
            '-image': _vm.image,
            '-fullscreen': _vm.fullscreen,
          },on:{"click":_vm.handleModalClick}},[_vm._t("default")],2)])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }