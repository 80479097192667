export default [
  {
    component: () => import('@/modules/communities/views/Community.vue'),
    name: 'community',
    path: '/community/:id',
    meta: {
      requiresModule: 'communities',
    },
  },
]
