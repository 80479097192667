module.exports = {
  menu: 'Menü',
  quiz: 'Quiz-Battle',
  profile: 'Profil',
  vouchers: 'Voucher-Codes',
  feedback: 'Feedback / App bewerten',
  statistics: 'Statistiken',
  tests: 'Tests',
  home: 'Home',
  notification_settings: 'Notifications',
  features: 'Funktionen',
  information: 'Informationen',
  logout: 'Ausloggen',
  learning: 'Lernen',
  training: 'Training',
  webinars: 'Webinare',
  mediacenter: 'Mediathek',
  courses: 'Kurse',
  powerlearning: 'Powerlearning',
  language: 'Sprache',
  appointments: 'Termine',
  profile_settings: 'Profildaten ändern',
  more: 'Mehr',
  todolists: 'Aufgaben',
  communities: 'Communities',
}
