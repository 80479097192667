import { mapGetters } from 'vuex'

import LanguageSelector from '@/components/LanguageSelector.vue'
import { useCommunitiesStore } from '@/modules/communities/store/communitiesStore'

export default {
  computed: {
    ...mapGetters({
      hasModule: 'auth/hasModule',
      config: 'auth/config',
    }),
    navigation() {
      const navigationData = [
        {
          label: this.$t('nav.home'),
          icon: 'home',
          route: {
            name: 'home',
          },
          visible() {
            return true
          },
          children: this.communityNavEntries,
        },
        {
          label: this.$t('nav.quiz'),
          icon: 'quiz',
          route: {
            name: 'quizzes.overview',
          },
          visible() {
            return this.hasModule.quiz
          },
        },
        {
          label: this.$t('nav.mediacenter'),
          icon: 'mediathek',
          route: {
            name: 'learningmaterials.dashboard',
          },
          visible() {
            return this.hasModule.learningmaterials
          },
        },
        {
          label: this.$t('nav.courses'),
          icon: 'kurse',
          route: {
            name: 'courses.dashboard',
          },
          visible() {
            return this.hasModule.courses
          },
        },
        {
          label: this.$t('nav.todolists'),
          icon: 'todolists',
          route: {
            name: 'todolists.dashboard',
          },
          visible() {
            return this.hasModule.todolists
          },
        },
        {
          label: this.$t('nav.powerlearning'),
          icon: 'powerlearning',
          route: {
            name: 'powerlearning.dashboard',
          },
          visible() {
            return this.hasModule.powerlearning
          },
        },
        {
          label: this.$t('nav.tests'),
          icon: 'tests',
          route: {
            name: 'tests.dashboard',
          },
          visible() {
            return this.hasModule.tests
          },
        },
        {
          divider: true,
        },
        {
          label: this.$t('nav.webinars'),
          icon: 'webinare',
          route: {
            name: 'webinars.dashboard',
          },
          visible() {
            return this.hasModule.webinars
          },
        },
        {
          label: this.$t('nav.appointments'),
          icon: 'calendar',
          route: {
            name: 'appointments.index.upcoming',
          },
          visible() {
            return this.hasModule.appointments
          },
        },
        {
          label: this.$t('news.news'),
          icon: 'news',
          route: {
            name: 'news.news',
          },
          visible() {
            return this.hasModule.news
          },
        },
        {
          label: 'Chat',
          route: {
            name: 'chat.index',
          },
        },
      ]

      return navigationData.filter((entry) => {
        if (!entry.visible) {
          return true
        }
        return entry.visible.apply(this)
      })
    },
    communityNavEntries() {
      if (!this.hasModule.communities) {
        return []
      }
      const communitiesStore = useCommunitiesStore()
      return communitiesStore.communities.map((community) => ({
        label: community.name,
        // icon: 'community',
        icon: 'home',
        route: {
          name: 'communities.community',
          params: { id: community.id },
        },
      }))
    },
  },
  components: {
    LanguageSelector,
  },
}
