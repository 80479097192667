<template>
  <portal to="modal">
    <transition name="modal">
      <div
        v-if="isOpen"
        class="c-modal__backdrop"
        @click="isOpen = false"
        @keydown.esc="isOpen = false">
        <button
          v-if="image || fullscreen"
          class="o-unstyled c-modal__iconClose o-flex -alignCenter -center"
          :class="{
            '-fullscreen': fullscreen,
          }"
          @click="isOpen = false">
          <template v-if="fullscreen">
            {{ $t('generic.close') }}
          </template>
          <SvgIcon
            type="close"
            medium />
        </button>
        <div
          class="c-modal__wrapper"
          :class="{
            '-image': image,
            '-fullscreen': fullscreen,
          }"
          data-cy="GenericModal__window">
          <!-- eslint-disable-next-line vuejs-accessibility/click-events-have-key-events -->
          <div
            class="c-modal"
            :class="{
              '-large': large,
              '-wide': wide,
              '-mediumWide': mediumWide,
              '-scroll': scroll,
              '-image': image,
              '-fullscreen': fullscreen,
            }"
            @click="handleModalClick">
            <slot />
          </div>
        </div>
      </div>
    </transition>
  </portal>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    large: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    mediumWide: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isOpen: {
      set(isOpen) {
        this.$emit('input', isOpen)
      },
      get() {
        return this.value
      },
    },
  },
  methods: {
    handleModalClick($event) {
      $event.stopPropagation()
    },
  },
}
</script>
