<template>
  <router-link
    tag="a"
    class="c-learningMaterialListEntry t-noLinkAppearance"
    data-cy="LearningmaterialListEntry"
    :to="{
      name: 'learningmaterials.details',
      params: {
        id: learningmaterial.id,
      },
    }">
    <div class="o-flex -row">
      <div class="c-learningMaterialListEntry__content">
        <div class="c-learningMaterialListEntry__image">
          <img
            v-if="learningmaterial.cover_image_url"
            :src="learningmaterial.cover_image_url" />
          <img
            v-else-if="isImage"
            :src="learningmaterial.file_url" />
          <img
            v-else-if="$store.getters['auth/config'].settings.media_library_default_image"
            :src="$store.getters['auth/config'].settings.media_library_default_image" />
          <img
            v-else
            src="@/assets/images/kurse/cards/weltraum.svg" />
        </div>
        <div class="c-learningMaterialListEntry__name">
          <div
            class="t-caption2 t-uppercase t-fontWeightMedium t-breakWords u-colorPrimary"
            data-cy="LearningmaterialListEntry__title">
            {{ folder.name }}
            <div
              v-if="bestWBTEvent"
              class="c-learningMaterialListEntry__label t-caption2 t-fontWeightMedium u-colorWhite u-bgPrimary">
              {{ $t('learningpath.passed') }}
              <template v-if="bestWBTEvent.score">
                {{ $t('learningpath.with_percentage', { score: bestWBTEvent.score }) }}
              </template>
            </div>
          </div>
          <div
            class="t-body3 t-fontWeightMedium t-breakWords"
            data-cy="LearningmaterialListEntry__fileName"
            v-html="title" />
        </div>
        <div class="c-learningMaterialListEntry__typeIcon">
          <SvgIcon
            class="u-hide@tabletMiniDown s-icon -largeWithBackground"
            data-cy="LearningmaterialListEntry__typeIcon"
            :type="`filetype_${learningmaterial.typeIcon}`" />
          <SvgIcon
            class="u-hide@tabletPortrait"
            data-cy="LearningmaterialListEntry__typeIcon"
            :type="`filetype_${learningmaterial.typeIcon}`"
            large />
        </div>
        <div class="c-learningMaterialListEntry__Stats">
          <div
            class="c-learningMaterialListEntry__commentCount"
            data-cy="LearningmaterialListEntry__commentCount">
            <router-link
              tag="a"
              :to="{
                name: 'learningmaterials.details',
                params: {
                  id: learningmaterial.id,
                },
                hash: '#comments',
              }"
              class="t-noLinkAppearance">
              <CommentCount
                :count="learningmaterial.comment_count"
                tiny />
            </router-link>
          </div>
          <div
            class="c-learningMaterialListEntry__likeCount"
            data-cy="LearningmaterialListEntry__likeCount">
            <LikeCount
              v-if="config.settings.enable_social_features"
              :count="learningmaterial.likes_count"
              :liked="learningmaterial.likes_it"
              medium />
          </div>
        </div>
        <div class="c-learningMaterialListEntry__new">
          <div
            v-if="!learningmaterial.last_viewed_at"
            class="t-caption2 u-colorPrimary u-bgLightGray u-inlineBlock">
            {{ $t('learningpath.new') }}
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import escapeRegexp from 'escape-string-regexp'
import { mapGetters } from 'vuex'

import CommentCount from '@/modules/comments/components/CommentCount.vue'
import LikeCount from '@/modules/likes/components/LikeCount.vue'

export default {
  props: {
    learningmaterial: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      config: 'auth/config',
      findWBTEvents: 'learningmaterials/findWBTEvents',
      findLearningmaterialFolder: 'learningmaterials/findLearningmaterialFolder',
    }),
    folder() {
      if (this.learningmaterial.learning_material_folder) {
        return this.learningmaterial.learning_material_folder
      }
      return this.findLearningmaterialFolder(this.learningmaterial.learning_material_folder_id)
    },
    title() {
      const query = this.$store.getters['learningmaterials/searchQuery']
      if (!query) {
        return this.learningmaterial.title
      }
      const regex = new RegExp(`(${escapeRegexp(query)})`, 'gi')
      return this.learningmaterial.title.replace(regex, '<span class="c-highlight">$1</span>')
    },
    isImage() {
      return this.learningmaterial.type === this.$constants.LEARNINGMATERIALS.TYPE_DOCUMENT_IMAGE
    },
    bestWBTEvent() {
      const events = this.findWBTEvents(this.learningmaterial.id)
      if (!events) {
        return null
      }
      return events.reduce((bestEvent, event) => {
        if (!['completed', 'passed'].includes(event.status)) {
          return bestEvent
        }
        if (!bestEvent) {
          return event
        }
        if (bestEvent.score < event.score) {
          return event
        }
        return bestEvent
      }, null)
    },
  },
  components: {
    CommentCount,
    LikeCount,
  },
}
</script>

<style lang="scss" scoped>
.s-icon {
  &.-largeWithBackground {
    font-size: 24px;
  }
}
</style>
